import React from "react";

function Facebook() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <g clipPath="url(#clip0_280_767)">
        <path
          fill="#fff"
          d="M13 0C5.832 0 0 5.832 0 13s5.832 13 13 13 13-5.832 13-13S20.169 0 13 0zm3.233 13.458h-2.115v7.538h-3.134v-7.538h-1.49v-2.665h1.49V9.07c0-1.234.586-3.163 3.163-3.163l2.322.01v2.585h-1.686c-.274 0-.664.138-.664.726v1.566h2.388l-.274 2.664z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_280_767">
          <path fill="#fff" d="M0 0H26V26H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Facebook;
