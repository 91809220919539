import React from "react";

function FooterLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 64 36">
      <g clipPath="url(#clip0_280_732)">
        <path
          fill="#fff"
          d="M0 22.425C0 29.925 6.331 36 14.052 36h35.054c7.798 0 14.052-6.075 14.052-13.575 0-7.5-6.331-13.575-14.052-13.575-1.159 0-2.317.15-3.475.375C43.855 3.9 38.605 0 32.351 0 26.02 0 20.615 4.05 18.917 9.675c-1.545-.525-3.166-.825-4.788-.825C6.332 8.85 0 14.925 0 22.425z"
        ></path>
        <path
          fill="#0E1C48"
          d="M23.228 21.3v6.9h-2.194V16.8h2.194v1.5c1.21-.975 2.95-1.65 5.372-1.65v2.1c-2.194 0-3.859.75-4.918 1.575-.303.3-.454.6-.454.975zM43.279 27.075c-1.211-.975-2.043-2.4-2.043-4.5v-5.85h2.42v5.85c0 1.5.53 2.325 1.136 2.775.68.525 1.664.75 2.724.75.983 0 1.664-.225 2.27-.6.605-.375.756-.75.756-1.2 0-.6-.303-1.05-.908-1.35-.757-.375-1.362-.6-2.043-.9-.908-.45-1.437-1.2-1.589-2.025-.151-.75.152-1.65.681-2.25.454-.45.984-.825 1.665-.975.605-.15 1.362-.15 2.345-.15h1.06v2.1h-1.06c-.908 0-1.21.075-1.589.15-.302.075-.68.225-.68.75 0 .375.453.6.605.675.454.15 1.135.45 1.891.825.984.6 2.043 1.575 2.043 3.225 0 1.425-.832 2.475-1.891 3.075-1.06.6-2.346.9-3.556.9-1.362 0-3.027-.3-4.237-1.275zM43.732 13.8h-2.421v1.8h2.421v-1.8zM19.823 21.6c0-1.95-.832-3.225-1.891-3.975-1.06-.75-2.27-.9-3.178-.9-3.33 0-5.75 2.475-5.75 5.7 0 3.975 2.8 5.925 6.053 5.925 1.437 0 2.8-.225 4.01-.75l-.53-2.025c-1.06.45-2.27.675-3.556.675-2.043 0-3.632-1.05-3.783-3.525h8.398l.227-1.125zm-8.322-.675c.53-1.35 1.664-2.175 3.253-2.175.53 0 1.286.15 1.891.525.454.3.757.75.908 1.65H11.5zM34.804 16.8c-.907 0-1.891.225-2.8.825v-3.6H29.66V27.3c1.438.75 2.951 1.05 4.843 1.05 3.102 0 5.599-2.025 5.599-5.925-.076-3.3-1.968-5.625-5.297-5.625zm-.378 9.45c-.832 0-1.664-.15-2.421-.375h-.076V21c0-.525.303-.975.908-1.35.605-.375 1.362-.6 1.892-.6 1.891 0 2.875 1.35 2.875 3.45.076 2.55-1.589 3.75-3.178 3.75z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_280_732">
          <path fill="#fff" d="M0 0H63.158V36H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default FooterLogo;
