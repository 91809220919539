import React, { useEffect } from "react";

export const UaPromo = () => {
  useEffect(() => {
    const handleScroll = () => {
      const promoBanner = document.getElementById("promo-banner");
      const topNavMenu = document.getElementById("top-nav-menu");
      const subMenus = document.querySelectorAll(".submenu");

      if (promoBanner && topNavMenu && subMenus.length > 0) {
        if (window.scrollY >= 10) {
          subMenus.forEach((subMenu) => {
            subMenu.classList.add("menu-position-anim");
          });
          topNavMenu.style.top = "0";
        } else {
          subMenus.forEach((subMenu) => {
            subMenu.classList.remove("menu-position-anim");
          });

          topNavMenu.style.top = "";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div id="promo-banner" className="ua-promo">
      <span>Erbis stands with Ukraine</span>
    </div>
  );
};
