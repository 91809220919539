import React from "react";
import { Link } from "gatsby";
import classnames from "classnames";

import { usePagination, DOTS } from "../../hooks/usePagination";

import "../../assets/styles/pagination.css";

const Pagination = ({
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const prevPage = currentPage === 2 ? "/blog/" : `/blog/${currentPage - 1}/`;
  const nextPage = `/blog/${currentPage + 1}/`;
  const lastPage = paginationRange[paginationRange.length - 1];

  return (
    <ul className="pagination-container">
      <Link
        to={prevPage}
        className={classnames("pagination-item", {
          disabled: currentPage === 1,
        })}
      >
        <div className="arrow left" />
      </Link>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <li key={pageNumber} className="pagination-item dots">
              &#8230;
            </li>
          );
        }

        return (
          <Link
            to={pageNumber === 1 ? "/blog/" : `/blog/${pageNumber}/`}
            key={pageNumber}
            className={classnames("pagination-item", {
              selected: pageNumber === currentPage,
            })}
          >
            {pageNumber}
          </Link>
        );
      })}
      <Link
        to={nextPage}
        className={classnames("pagination-item", {
          disabled: currentPage === lastPage,
        })}
      >
        <div className="arrow right" />
      </Link>
    </ul>
  );
};

export default Pagination;
