import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby";

import { Header } from ".";
import { Footer } from ".";
import { UaPromo } from "../PromoBanner/UaPromo";
import { Cookie } from "../CookieBanner/index";
import { сookiePolicy } from "../CookieBanner/politicsTranslation";
import "../../assets/styles/erb-message.css";

// import PopUp from "./PopUp";

const COOKIE_BANNER_ACCEPTED_KEY = "cookieBannerAccepted";
// const url =
//   "https://www.linkedin.com/events/techupgrade-5thingstoconsiderbe7128732159795769344/theater/";

const Layout = ({ children }) => {
  const [visibleBanner, setVisibleBanner] = useState(false);
  // const [popUpVisible, setPopUpVisible] = useState(false);

  useEffect(() => {
    const isCookieBannerAccepted = localStorage.getItem(
      COOKIE_BANNER_ACCEPTED_KEY
    );
    if (!isCookieBannerAccepted) {
      setVisibleBanner(true);
    }
  }, []);

  // useEffect(() => {
  //   const hasVisitedBefore = localStorage.getItem("visitedBefore");

  //   if (!hasVisitedBefore) {
  //     const timeoutId = setTimeout(() => {
  //       setPopUpVisible(true);
  //       localStorage.setItem("visitedBefore", "true");
  //     }, 4000);

  //     return () => clearTimeout(timeoutId);
  //   }
  // }, []);

  const handleAcceptCookie = () => {
    localStorage.setItem(COOKIE_BANNER_ACCEPTED_KEY, "true");
    setVisibleBanner(false);
  };
  // const handleClosePopUp = () => {
  //   setPopUpVisible(false);
  // };

  return (
    <>
      <Helmet>
        <meta charset="UTF-8" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:image:alt" content="Erbis logo" />
        <meta property="og:url" content="https://erbis.com" />
        <meta property="og:image:width" content="200" />
        <meta property="og:image:height" content="200" />
        <meta
          property="og:image"
          content={withPrefix("assets/icons/logo-129a1e413e75063175fb.png")}
        />

        <meta
          http-equiv="cache-control"
          content="no-cache, no-store, must-revalidate"
        />
        <meta http-equiv="pragma" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <link
          rel="shortcut icon"
          href={withPrefix("assets/favicon/favicon.ico")}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={withPrefix("assets/favicon/favicon-16x16.png")}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={withPrefix("assets/favicon/favicon-32x32.png")}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="48x48"
          href={withPrefix("assets/favicon/favicon-48x48.png")}
        />
        <link
          rel="manifest"
          href={withPrefix("assets/favicon/manifest.json")}
        />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="theme-color" content="#fff" />
        <meta name="application-name" content="Erbis" />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href={withPrefix("assets/favicon/apple-touch-icon-57x57.png")}
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href={withPrefix("assets/favicon/apple-touch-icon-60x60.png")}
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href={withPrefix("assets/favicon/apple-touch-icon-72x72.png")}
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href={withPrefix("assets/favicon/apple-touch-icon-76x76.png")}
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href={withPrefix("assets/favicon/apple-touch-icon-114x114.png")}
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href={withPrefix("assets/favicon/apple-touch-icon-120x120.png")}
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href={withPrefix("assets/favicon/apple-touch-icon-144x144.png")}
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href={withPrefix("assets/favicon/apple-touch-icon-152x152.png")}
        />
        <link
          rel="apple-touch-icon"
          sizes="167x167"
          href={withPrefix("assets/favicon/apple-touch-icon-167x167.png")}
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={withPrefix("assets/favicon/apple-touch-icon-180x180.png")}
        />
        <link
          rel="apple-touch-icon"
          sizes="1024x1024"
          href={withPrefix("assets/favicon/apple-touch-icon-1024x1024.png")}
        />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta name="apple-mobile-web-app-title" content="Erbis" />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-640x1136.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-750x1334.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-828x1792.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-1125x2436.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-1242x2208.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-1242x2688.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-1536x2048.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-1668x2224.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-1668x2388.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-2048x2732.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-1620x2160.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-1136x640.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-1334x750.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-1792x828.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-2436x1125.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-2208x1242.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-2688x1242.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-2048x1536.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-2224x1668.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-2388x1668.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-2732x2048.png"
          )}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
          href={withPrefix(
            "assets/favicon/apple-touch-startup-image-2160x1620.png"
          )}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="228x228"
          href={withPrefix("assets/favicon/coast-228x228.png")}
        />
        <meta name="msapplication-TileColor" content="#fff" />
        <meta
          name="msapplication-TileImage"
          content={withPrefix("assets/favicon/mstile-144x144.png")}
        />
        <meta
          name="msapplication-config"
          content={withPrefix("assets/favicon/browserconfig.xml")}
        />
        <link
          rel="yandex-tableau-widget"
          href={withPrefix("assets/favicon/yandex-browser-manifest.json")}
        />
      </Helmet>
      <UaPromo />
      <Header />
      {/* <PopUp isVisible={popUpVisible} onClose={handleClosePopUp} link={url} /> */}
      {visibleBanner &&
        сookiePolicy.map(
          (
            {
              description,
              link,
              linkName,
              btn1,
              btn2,
              cookies,
              cancel,
              accept,
            },
            index
          ) => (
            <Cookie
              key={index}
              description={description}
              link={link}
              linkName={linkName}
              btn1={btn1}
              btn2={btn2}
              cookies={cookies}
              cancel={cancel}
              accept={accept}
              onAccept={handleAcceptCookie}
            />
          )
        )}
      <main id="main">
        {children}
        <Footer />
      </main>
      <div className="erb-message animated">
        <span></span>
      </div>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-T9PBQ8V2"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
          title="frame"
        ></iframe>
      </noscript>
    </>
  );
};

export default Layout;
