import React from "react";

function Retail() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g clipPath="url(#clip0_7689_51)">
        <path d="M32 17.17V6.48a.469.469 0 00-.469-.468h-3.038V.47A.469.469 0 0028.024 0H16a.469.469 0 00-.469.469v2.537H10.49a.469.469 0 00-.469.469v2.537H7.527l-.932-3.65A2.472 2.472 0 004.2.501H1.471C.66.501 0 1.161 0 1.971c0 .812.66 1.471 1.47 1.471h2.365l5.248 20.556a3.974 3.974 0 003.853 2.992h17.593c.811 0 1.471-.66 1.471-1.47 0-.812-.66-1.472-1.47-1.472H12.935c-.473 0-.885-.32-1.002-.778l-.472-1.845 18.8-2.297A1.972 1.972 0 0032 17.17zM10.958 3.944h7.58v2.068h-7.58V3.944zm.27 16.565l-2.79-10.93a.469.469 0 00-.908.232l3.495 13.69a1.97 1.97 0 001.91 1.485H30.53a.534.534 0 010 1.066H12.936c-1.39 0-2.6-.94-2.944-2.286L4.654 2.858a.469.469 0 00-.455-.353H1.471a.534.534 0 010-1.066h2.728c.703 0 1.314.475 1.488 1.155L7.03 7.85a.469.469 0 00.908-.232l-.17-.667h17.251a.469.469 0 100-.938h-5.543V3.475a.469.469 0 00-.469-.469H16.47V.938h11.086v5.074h-.533a.469.469 0 100 .938h4.04v10.22c0 .524-.392.965-.913 1.027L11.23 20.51zM9.988 28.056a1.974 1.974 0 00-1.972 1.972c0 1.087.885 1.972 1.972 1.972a1.974 1.974 0 001.972-1.972 1.974 1.974 0 00-1.972-1.972zm0 3.006a1.035 1.035 0 010-2.068 1.035 1.035 0 010 2.069zM30.028 28.056a1.974 1.974 0 00-1.971 1.972c0 1.087.884 1.972 1.971 1.972A1.974 1.974 0 0032 30.028a1.974 1.974 0 00-1.972-1.972zm0 3.006a1.035 1.035 0 010-2.068 1.035 1.035 0 010 2.069z"></path>
        <path d="M12.994 9.018a.469.469 0 00-.469.469v8.261a.469.469 0 00.938 0V9.487a.469.469 0 00-.469-.469zM16 9.018a.469.469 0 00-.469.469v7.885a.469.469 0 00.938 0V9.487A.469.469 0 0016 9.018zM19.006 9.018a.469.469 0 00-.469.469v7.525a.469.469 0 00.938 0V9.487a.469.469 0 00-.469-.469zM22.012 9.018a.469.469 0 00-.469.469v7.15a.469.469 0 00.938 0v-7.15a.469.469 0 00-.469-.469zM25.018 9.018a.469.469 0 00-.469.469v6.774a.469.469 0 00.938 0V9.487a.469.469 0 00-.469-.469zM28.024 9.018a.469.469 0 00-.469.469v6.43a.469.469 0 10.938 0v-6.43a.469.469 0 00-.469-.469z"></path>
      </g>
      <defs>
        <clipPath id="clip0_7689_51">
          <path fill="#fff" d="M0 0H32V32H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Retail;
