import React from "react";

function SoftwareEngineering() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g clipPath="url(#clip0_7689_161)">
        <path d="M30.153 3.333H27.7V1.9a1.887 1.887 0 00-1.813-1.947H6A1.887 1.887 0 004.207 1.9v1.433h-2.36A1.847 1.847 0 000 5.14v18.387a1.84 1.84 0 001.847 1.806h11.586c-.1 1.067-.56 3.454-2.666 3.454h-.46a1.799 1.799 0 00-1.807 1.8v.6a.787.787 0 00.78.813h13.387a.793.793 0 00.793-.787v-.6a1.807 1.807 0 00-1.807-1.8h-.46c-2.12 0-2.58-2.386-2.666-3.453h11.626A1.839 1.839 0 0032 23.527V5.14a1.847 1.847 0 00-1.847-1.807zm0 1A.847.847 0 0131 5.14v15.113h-3.333V4.307l2.486.026zM26.7 20.253H5.207V5.333H26.7v14.92zM6 .953h19.867a.893.893 0 01.813.947v2.407H5.207V1.9A.893.893 0 016 .953zM1.827 4.287h2.38v15.966H1V5.14a.847.847 0 01.847-.833l-.02-.02zM21.62 29.813a.807.807 0 01.807.8V31H9.487v-.387a.807.807 0 01.806-.8H21.62zm-2.813-1h-5.7a5.833 5.833 0 001.333-3.453h3.033a5.834 5.834 0 001.334 3.453zM30.14 24.36H1.847A.84.84 0 011 23.527v-2.274h30v2.274a.841.841 0 01-.847.833h-.013z"></path>
        <path d="M17.06 22.26h-2.12a.5.5 0 000 1h2.12a.5.5 0 000-1zM18.667 3.167h6.5a.5.5 0 100-1h-6.5a.5.5 0 000 1zM6.9 3.167a.5.5 0 00.5-.5.62.62 0 000-.194.54.54 0 00-.32-.284.5.5 0 00-.607.284.48.48 0 00-.073.194.5.5 0 00.5.5zM9.067 3.167a.5.5 0 100-1 .5.5 0 000 1zM11.233 3.167a.5.5 0 10-.353-.853.54.54 0 00-.113.16.62.62 0 000 .193.5.5 0 00.5.5h-.034zM9.333 12.927l2.58 2.58a.493.493 0 00.354.146.486.486 0 00.353-.146.492.492 0 000-.707l-2.227-2.227 2.274-2.233a.494.494 0 000-.707.5.5 0 00-.707 0L9.333 12.22a.493.493 0 000 .707zM19.267 15.507a.494.494 0 00.545.11.487.487 0 00.161-.11l2.587-2.58a.5.5 0 000-.707L20 9.633a.5.5 0 00-.707.707l2.234 2.233-2.26 2.227a.5.5 0 000 .707zM14.393 17.173c.053.01.107.01.16 0a.5.5 0 00.474-.346l2.726-8.36a.5.5 0 00-.946-.307l-2.734 8.353a.505.505 0 00.32.66z"></path>
      </g>
      <defs>
        <clipPath id="clip0_7689_161">
          <path fill="#fff" d="M0 0H32V32H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SoftwareEngineering;
