import React from "react";

function Fintech() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g clipPath="url(#clip0_7689_29)">
        <path d="M26.594 2.567c-2.445 0-4.377 1.962-4.377 4.407a4.36 4.36 0 004.377 4.377A4.412 4.412 0 0031 6.944c0-2.445-1.962-4.377-4.407-4.377zm0 7.818a3.44 3.44 0 01-3.441-3.44c0-1.903 1.57-3.412 3.44-3.412a3.44 3.44 0 013.442 3.441c0 1.902-1.54 3.411-3.441 3.411z"></path>
        <path d="M27.983 7.638c-.03-.09-.03-.15-.091-.241-.03-.06-.06-.151-.12-.211-.061-.06-.091-.121-.152-.181a.54.54 0 00-.211-.151c-.06-.06-.151-.091-.211-.121-.09-.03-.151-.09-.242-.12-.09-.031-.181-.061-.241-.091-.03 0-.09-.03-.121-.06-.03-.031-.09-.031-.12-.061-.031-.03-.091-.03-.122-.06l-.09-.091c-.03-.03-.06-.06-.06-.12-.03-.031-.03-.091-.03-.122v-.15c0-.03.03-.091.03-.121.03-.03.03-.09.06-.121l.09-.09a.23.23 0 01.151-.06c.06-.031.09-.031.151-.031h.181c.06 0 .091 0 .151.03.06 0 .121.03.151.03s.09.03.121.03c.06 0 .09.03.12.03l.152.061.03-.181.06-.483v-.12L27.5 4.8c-.03 0-.091-.03-.121-.03s-.06-.03-.121-.03c-.03 0-.09-.03-.15-.03-.061 0-.091-.03-.152-.03h-.03v-.574h-.724v.603h-.03c-.091.03-.182.06-.272.121-.09.03-.151.09-.212.151-.06.06-.12.12-.18.211-.061.06-.091.151-.121.242l-.091.272c-.03.09-.03.21-.03.301s0 .151.03.242c.03.09.03.15.09.241.03.06.091.151.121.212.06.06.09.12.151.18.06.061.12.122.211.152.06.06.151.09.212.12.09.03.18.091.241.121.09.03.181.06.242.09.03 0 .09.031.12.061s.091.03.121.06.06.06.09.06l.091.091c.03.03.03.06.06.121 0 .03.03.09.03.151v.151c0 .03-.03.09-.06.12l-.09.091c-.03.03-.06.06-.12.09-.031.031-.091.031-.122.061-.03 0-.09.03-.15.03h-.392c-.061 0-.122-.03-.152-.03-.06 0-.12-.03-.18-.06-.061-.03-.122-.03-.182-.06-.03 0-.06-.03-.09-.03s-.061-.03-.091-.03l-.181-.091-.03.21-.06.544v.09l.09.031c.12-.03.18 0 .271.03.06.03.151.06.212.06.06.03.15.03.241.06.09.031.151.031.242.031h.06v.603h.724v-.633h.03c.091-.03.182-.06.272-.12.09-.031.151-.091.242-.152.06-.06.12-.12.181-.211.06-.06.09-.151.15-.242l.092-.271c.03-.09.03-.181.03-.302 0-.09 0-.151-.03-.242zM24.48 11.925c-.15-.211-.482-.242-.693-.09l-4.89 3.893-6.31-2.626a.482.482 0 00-.452.06l-6.762 4.83a.482.482 0 00-.12.664.44.44 0 00.392.212c.09 0 .181-.03.272-.09l6.55-4.68 6.339 2.626c.15.06.362.03.483-.06l5.101-4.045c.212-.15.242-.483.09-.694z"></path>
        <path d="M31.544 28.467h-2.233V14.49a.477.477 0 00-.483-.483H24.39a.477.477 0 00-.483.483v13.976h-1.63V19.32a.477.477 0 00-.483-.483h-4.467a.477.477 0 00-.483.482v9.147h-1.63v-9.87a.477.477 0 00-.483-.484h-4.438a.477.477 0 00-.483.483v9.87H8.18v-7.032a.477.477 0 00-.483-.483H3.23a.477.477 0 00-.483.483v7.033H.483A.477.477 0 000 28.95c0 .271.211.483.483.483H31.514a.477.477 0 00.483-.483c.03-.272-.181-.483-.453-.483zm-24.36 0H3.713v-6.55h3.471v6.55zm7.064 0h-3.472v-9.388h3.472v9.388zm7.033 0H17.81v-8.663h3.471v8.663zm7.064 0h-3.472V14.974h3.472v13.493z"></path>
      </g>
      <defs>
        <clipPath id="clip0_7689_29">
          <path fill="#fff" d="M0 0H32V32H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Fintech;
