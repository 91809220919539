import React from "react";

function UIDesign() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <rect
        width="3"
        height="3"
        x="1.5"
        y="4.5"
        // stroke="#1C2F6B"
        rx="1.5"
      ></rect>
      <rect
        width="3"
        height="3"
        x="27.5"
        y="4.5"
        // stroke="#1C2F6B"
        rx="1.5"
      ></rect>
      <path
        // stroke="#1C2F6B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16 11L9.5 21v2l2.5 2.5M16 11l6.5 10v2L20 25.5M16 11v7.5m-4 7h8m-8 0h-1.5v3H12m0 0h8m-8 0v3m8-6h1.5v3H20m0 0v3M5 6h8.5M27 6h-8.5"
      ></path>
      <path
        // stroke="#1C2F6B"
        d="M27.914 16.567A12 12 0 0018.5 6.263M4.086 16.567A12 12 0 0113.5 6.263"
      ></path>
      <rect
        width="5"
        height="5"
        x="13.5"
        y="3.5"
        // stroke="#1C2F6B"
        rx="0.5"
      ></rect>
      <rect
        width="3"
        height="3"
        x="2.5"
        y="16.5"
        // stroke="#1C2F6B"
        rx="0.5"
      ></rect>
      <rect
        width="3"
        height="3"
        x="26.5"
        y="16.5"
        // stroke="#1C2F6B"
        rx="0.5"
      ></rect>
      <rect
        width="3"
        height="3"
        x="14.5"
        y="18.5"
        // stroke="#1C2F6B"
        rx="1.5"
      ></rect>
    </svg>
  );
}

export default UIDesign;
