import React, { StrictMode } from "react";

import { createScript } from "./src/utils/createScript";
import { GetInTouchProvider } from "./src/context/GetInTouchModal";

import "./src/assets/styles/constants.css";
import "./src/assets/styles/erb-logo.css";
import "./src/assets/styles/header.css";

import "/src/assets/styles/base-page.css";
import "./src/assets/styles/main.css";
import "./src/assets/styles/general.css";

import "/src/assets/styles/footer.css";
import "/src/assets/styles/ua-promo.css";
import "/src/assets/styles/cookie-banner.css";
import "/src/assets/styles/common.css";
import "/src/assets/styles/fonts-file.css";
import "/src/assets/styles/menu.css";

import "/src/assets/styles/erb-form.css";
import "/src/assets/styles/erb-button.css";
import "/src/assets/styles/case-studies-carousel.css";

import "/src/assets/styles/svg-icons.css";
import "/src/assets/styles/slick.css";
import "/src/assets/styles/slick-theme.css";
import "/src/assets/styles/ui-ux-design.css";
import "/src/assets/styles/software-engineering.css";
import "/src/assets/styles/discovery-phase.css";
import "/src/assets/styles/faq-services.css";

import "/src/assets/styles/market-research.css";
import "/src/assets/styles/product-development.css";
import "/src/assets/styles/cloud-migration.css";
import "/src/assets/styles/tech-client-support.css";

import "/src/assets/styles/modal.css";

import "toastify-js/src/toastify.css";
import "/src/assets/styles/servises.css";
import "/src/assets/styles/home.css";
import "/src/assets/styles/pop-up.css";
import "/src/assets/styles/digital-transformation.css";
import "/src/assets/styles/mobile-development.css";

import "/src/assets/styles/scm-supply-chain.css";

import "/src/assets/styles/fintech-solutions.css";
import "/src/assets/styles/industries.css";
import "/src/assets/styles/careers-page.css";

let shouldLoadScripts = true;

const device = navigator.userAgent;

export const onRouteUpdate = ({ location }) => {
  if (shouldLoadScripts) {
    if (["/services/product-development/"].includes(location.pathname)) {
      if (
        /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(device) ||
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          device
        )
      ) {
      } else {
        const tooltip = createScript("tooltip.js", true, false);

        document.body.appendChild(tooltip);

        document.body.removeChild(tooltip);
      }
    }
    if (
      [
        "/industries/retail-software-development/",
        "/industries/construction-real-estate-software/",
        "/industries/automotive-software-development/",
        "/services/product-development/",
        "/services/ui-ux-design/",
        "/services/market-research/",
      ].includes(location.pathname)
    ) {
      if (
        /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(device) ||
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
          device
        )
      ) {
      } else {
        const animation = createScript("particle-spread.js", true, false);

        document.body.appendChild(animation);
        document.body.removeChild(animation);
      }
    }

    if (location.pathname.includes("/industries/")) {
      const animation = createScript("page-slides.js", true, false);

      document.body.appendChild(animation);
      document.body.removeChild(animation);
    }

    if (location.pathname.endsWith("//")) {
      window.location.replace(location.pathname.slice(0, -1));
    }
  }

  if (
    process.env.NODE_ENV !== "development" &&
    window.location.hostname === "https://www.erbis.com/"
  ) {
    function initGTM() {
      if (window.isGTMLoaded) {
        return false;
      }

      window.isGTMLoaded = true;
      if (document.readyState !== "loading") {
        loadGTM();
      } else {
        document.addEventListener("DOMContentLoaded", loadGTM);
      }
    }

    function loadGTM() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });

      var script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = "https://www.googletagmanager.com/ns.html?id=GTM-T9PBQ8V2";

      var firstScript = document.getElementsByTagName("script")[0];
      firstScript.parentNode.insertBefore(script, firstScript);
    }

    // Attach initGTM to the desired event (e.g., a button click or scroll)
    document.addEventListener("scroll", initGTM);
    document.addEventListener("click", initGTM);
    document.addEventListener("mousemove", loadGTM);
    document.addEventListener("touchstart", loadGTM);

    // Additional check on initial page load
    if (document.readyState !== "loading") {
      setTimeout(initGTM, 3500);
    }
  }
};

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  shouldLoadScripts = location.pathname !== prevLocation?.pathname;
};

export const wrapRootElement = ({ element }) => (
  <StrictMode>
    <GetInTouchProvider>{element}</GetInTouchProvider>
  </StrictMode>
);
