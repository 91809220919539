import React from "react";

function Linkedin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <g clipPath="url(#clip0_280_763)">
        <path
          fill="#fff"
          d="M13 0c7.18 0 13 5.82 13 13s-5.82 13-13 13S0 20.18 0 13 5.82 0 13 0z"
        ></path>
        <path
          fill="#0E1C48"
          d="M7.326 17.862H9.76V9.748H7.326v8.114zm9.483-8.395c-1.181 0-2.238.432-2.988 1.384v-1.13h-2.444v8.141h2.444V13.46c0-.93.852-1.838 1.92-1.838 1.067 0 1.33.908 1.33 1.815v4.424h2.435v-4.605c0-3.199-1.515-3.789-2.697-3.789zm-8.278-.53a1.22 1.22 0 100-2.438 1.22 1.22 0 000 2.438z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_280_763">
          <path fill="#fff" d="M0 0H26V26H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Linkedin;
