import React, { useEffect } from "react";

function СalendlyForm() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div
      className="calendly-inline-widget"
      data-url="https://mioji.youcanbook.me/"
      style={{
        minWidth: "320px",
        height: "670px",
      }}
    />
  );
}

export default СalendlyForm;
