import React, { useState } from "react";
import { Link } from "gatsby";

const Cookie = ({
  description,
  link,
  linkName,
  btn1,
  btn2,
  cookies,
  cancel,
  accept,
  onAccept,
}) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const showModal = () => {
    setVisibleModal(true);
  };
  const handleAccept = () => {
    setVisibleModal(false);
    onAccept();
  };
  const [cookieSettings, setCookieSettings] = useState(
    cookies.map((cookie) => ({ ...cookie, enabled: true }))
  );

  const handleCheckboxChange = (id) => {
    setCookieSettings((prevSettings) =>
      prevSettings.map((cookie) => {
        if (cookie.id === "1") {
          return cookie;
        }
        return cookie.id === id
          ? { ...cookie, enabled: !cookie.enabled }
          : cookie;
      })
    );
  };
  return (
    <div id="cookieConsent">
      {!visibleModal && (
        <div className="cookie-consent-container flex-row-space-between align-items-center">
          <div className="cookie-wrapper ">
            <div className="text">
              {description}
              <Link to={link}>
                {" "}
                <span>{linkName}.</span>
              </Link>
            </div>
            <div className="text-nowrap">
              <button
                className="settings-button erb-button erb-button-primary flex-center"
                onClick={showModal}
              >
                {btn1}
              </button>
              <button
                className="accept-button erb-button erb-button-white flex-center"
                onClick={handleAccept}
              >
                {btn2}
              </button>
            </div>
          </div>
        </div>
      )}

      {visibleModal && (
        <div className="cookie-settings-modal ">
          <div className="modal-content">
            {cookies.map(({ id, title, subTitle }) => (
              <div className="cookie-category mb-2" key={id}>
                <h4>{title}</h4>
                <div className="d-flex mt-2">
                  <label className="switch mr-3">
                    {" "}
                    <input
                      type="checkbox"
                      name="strictly-necessary "
                      onChange={() => handleCheckboxChange(id)}
                      checked={
                        cookieSettings.find((cookie) => cookie.id === id)
                          ?.enabled
                      }
                    />
                    <span className="slider" />
                  </label>
                  <p>{subTitle}</p>
                </div>
              </div>
            ))}
            <div className="d-flex flex-wrap ">
              <button
                className="erb-button erb-button-white text-truncate flex-center first-btn"
                onClick={handleAccept}
              >
                {cancel}
              </button>
              <button
                className="proceed-button erb-button erb-button-primary text-truncate flex-center  second-btn"
                onClick={handleAccept}
              >
                {accept}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cookie;
