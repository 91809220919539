import React from "react";

function Twitter() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <circle cx="13" cy="13" r="13" fill="#fff"></circle>
      <path
        fill="#0E1C48"
        d="M14.233 11.986l4.827-5.611h-1.144l-4.19 4.871-3.348-4.871h-3.86l5.061 7.367-5.062 5.883h1.144l4.426-5.145 3.535 5.145h3.86l-5.249-7.64zm-1.566 1.82l-.513-.733-4.08-5.837H9.83l3.293 4.71.513.734 4.28 6.123H16.16l-3.493-4.997z"
      ></path>
    </svg>
  );
}

export default Twitter;
