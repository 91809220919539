const сookiePolicy = [
  {
    description:
      "We use cookies to improve your experience with our site, including analytics and personalisation. By continuing to use the service, you agree to our use of cookies as described in the ",
    link: "/policy/cookie/",
    linkName: "Cookie Policy",
    btn1: "Settings",
    btn2: "Accept",
    cookies: [
      {
        id: "1",
        title: "Strictly necessary cookies",
        subTitle:
          "These cookies are essential for you to browse the website and use its features.",
      },
      {
        id: "2",
        title: "Preferences cookies",
        subTitle:
          "These cookies allow a website to remember choices you have made in the past, like what language you prefer, or what your username is.",
      },
      {
        id: "3",
        title: "Statistics cookies",
        subTitle:
          "These cookies collect information about how you use a website, like which pages you visited and which links you clicked on.",
      },
    ],
    cancel: "Continue without accepting",
    accept: "Proceed with selected cookies",
  },
];

const сookiePolicyDe = [
  {
    description:
      "Wir verwenden Cookies, um Ihre Erfahrung mit unserer Website zu verbessern, einschließlich Analyse und Personalisierung. Wenn Sie den Service weiterhin nutzen, stimmen Sie unserer Verwendung von Cookies gemäß der ",
    link: "/de/policy/cookie/",
    linkName: "Cookie-Richtlinie",
    btn1: "Einstellungen",
    btn2: "Akzeptieren",
    cookies: [
      {
        id: "1",
        title: "Unbedingt notwendige Cookies",
        subTitle:
          "Diese Cookies sind für das Durchsuchen der Website und die Verwendung ihrer Funktionen unerlässlich.",
      },
      {
        id: "2",
        title: "Präferenz-Cookies",
        subTitle:
          "Diese Cookies ermöglichen einer Website, sich an getroffene Entscheidungen aus der Vergangenheit zu erinnern, wie z.B. Ihre Spracheinstellung oder Ihren Benutzernamen.",
      },
      {
        id: "3",
        title: "Statistik-Cookies",
        subTitle:
          "Diese Cookies sammeln Informationen darüber, wie Sie eine Website nutzen, z.B. welche Seiten Sie besucht haben und auf welche Links Sie geklickt haben.",
      },
    ],
    cancel: "Ohne Zustimmung fortfahren",
    accept: "Ausgewählte genehmigen",
  },
];

export { сookiePolicy, сookiePolicyDe };
