import React from "react";

function SupplyChain() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path d="M29.5 20.5a.5.5 0 00-.5-.5h-7a.5.5 0 00-.5.5v3H.5v1h2.243l2.143 3-2.143 3H.5v1h31v-1H16.971l1.936-2.71a.5.5 0 000-.58l-1.936-2.71H21.5v3a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-3h2v-1h-2v-3zm-11.614 7l-2.143 3H3.97l1.936-2.71a.5.5 0 000-.58L3.971 24.5h11.771l2.143 3zM28.5 27h-6v-6H25v1.5h1V21h2.5v6z"></path>
      <path d="M9 6.73V10a3.504 3.504 0 00-3.5 3.5v2a.5.5 0 00.758.429L7 15.483V22a.5.5 0 00.5.5h7a.5.5 0 00.5-.5v-6.517l.742.446a.5.5 0 00.758-.429v-2A3.504 3.504 0 0013 10V6.73A2.997 2.997 0 108.77 2.5H.5v1h7.675a2.944 2.944 0 000 2H.5v1h8.27c.073.08.15.158.23.23zm5 14.77H8v-6h2.5V17h1v-1.5H14v6zm-5.361-7L11 13.083l2.361 1.417H8.639zm6.861-1v1.117l-4.242-2.545a.5.5 0 00-.515 0L6.5 14.617V13.5A2.503 2.503 0 019 11h4a2.503 2.503 0 012.5 2.5zM10 10V7.325a2.945 2.945 0 002 0V10h-2zm1-7.5a2 2 0 110 4 2 2 0 010-4z"></path>
    </svg>
  );
}

export default SupplyChain;
