import React from "react";

function DiscoveryPhase() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g clipPath="url(#clip0_7689_178)">
        <path d="M31.5 31h-5.393l-.46-4.193a3.493 3.493 0 003.266-3.474v-7.2a.493.493 0 00-.5-.5H26.58v-.3A1.674 1.674 0 0025.853 14a3.02 3.02 0 00.78-2v-1.067a3.053 3.053 0 00-6.106 0v1.02a3.047 3.047 0 002.04 2.867l-2.827 2.827h-2.98a2.16 2.16 0 00-1.427-1.78l-2.046-.714V14.207a3.026 3.026 0 001.006-2.254v-1.02a3.054 3.054 0 10-6.1 0v1.02a3.034 3.034 0 001.06 2.3v.894l-2.053.72a2.173 2.173 0 00-1.447 2.046v1.747H3.587a.5.5 0 00-.5.5v1.947a.5.5 0 00.5.5h1.08V31H.5a.5.5 0 000 1h31a.5.5 0 100-1zm-3.587-7.667a2.5 2.5 0 01-2.5 2.5H19.86V24.5h4.213A2.507 2.507 0 0026.58 22v-5.333h1.333v6.666zm-6.96-.726a.5.5 0 00.5-.5V20.2l3.507-3.533a1.507 1.507 0 00.113-2 .667.667 0 01.507.666V22a1.506 1.506 0 01-1.507 1.507H19.86v-.9h1.093zm-1.093 4.22h2.807L23.12 31h-3.26v-4.173zm1.667-14.874v-1.02a2.053 2.053 0 114.106 0v1.02a2.053 2.053 0 01-4.106 0zm-1.58 6.714a.5.5 0 00.353-.147l3.233-3.253a.507.507 0 01.865.36.52.52 0 01-.145.36L20.76 19.48a.713.713 0 01-.42.18h-5.06a.507.507 0 110-1.013l4.667.02zM9.2 10.933a2.053 2.053 0 114.1 0v1.02a2.054 2.054 0 11-4.1 0v-1.02zm2.053 4.074a3 3 0 001.04-.194v.287a1.02 1.02 0 01-2.033 0v-.273c.318.117.654.178.993.18zm-4.5 2.906a1.166 1.166 0 01.78-1.1l2-.706a2 2 0 003.467 0l2 .666a1.165 1.165 0 01.74.834h-.46a1.507 1.507 0 00-1.507 1.506c0 .173.032.345.094.507H6.753v-1.707zm-2.666 2.754h16.366v.946H4.087v-.946zM5.667 31v-8.393H18.86V31H5.667zm18.486 0l-.453-4.173h.967l.46 4.173h-.974z"></path>
        <path d="M12.793 6.307h2.234V8.46a.485.485 0 00.253.433.493.493 0 00.507 0l4.26-2.58h3.84a.5.5 0 00.5-.5V.72a.5.5 0 00-.5-.5H12.793a.5.5 0 00-.5.5v5.087a.5.5 0 00.5.5zm.5-5.087h10.094v4.113h-3.48a.467.467 0 00-.26.074L16 7.573V5.807a.5.5 0 00-.473-.474h-2.234V1.22z"></path>
        <path d="M14.353 2.953h8.2a.5.5 0 100-1h-8.2a.5.5 0 000 1zM19.793 4.08a.507.507 0 00-.5-.5h-4.94a.5.5 0 000 1h4.94a.5.5 0 00.5-.5z"></path>
      </g>
      <defs>
        <clipPath id="clip0_7689_178">
          <path fill="#fff" d="M0 0H32V32H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default DiscoveryPhase;
