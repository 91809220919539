import React from "react";

function Automotive() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g clipPath="url(#clip0_7689_63)">
        <path d="M16 0C7.163 0 0 7.163 0 16s7.163 16 16 16 16-7.163 16-16C31.99 7.168 24.832.01 16 0zm14.355 20.104l-1.002-.326-.33 1.013 1.006.327a14.928 14.928 0 01-1.645 3.218l-.854-.619-.627.863.847.616a15.078 15.078 0 01-2.553 2.556l-.615-.847-.863.627.619.854a14.926 14.926 0 01-3.218 1.643l-.327-1.006-1.013.33.326 1.002a14.854 14.854 0 01-3.573.565v-1.053h-1.066v1.053a14.853 14.853 0 01-3.57-.565l.325-1.002-1.013-.33-.329 1.006a14.928 14.928 0 01-3.218-1.645l.619-.854-.863-.627-.616.847a15.077 15.077 0 01-2.555-2.555l.847-.615-.627-.863-.853.619a14.926 14.926 0 01-1.64-3.216l1.006-.327-.33-1.013-1.003.326a14.853 14.853 0 01-.567-3.573h1.053v-1.066H1.08c.042-1.209.232-2.408.565-3.57l1.002.325.33-1.013-1.004-.329A14.93 14.93 0 013.62 7.662l.854.619.627-.863-.852-.615a15.077 15.077 0 012.555-2.555l.615.847.863-.627-.619-.854a14.924 14.924 0 013.218-1.64l.327 1.006 1.013-.33-.326-1.003a14.853 14.853 0 013.573-.567v1.053h1.066V1.08c1.209.042 2.408.232 3.57.565l-.325 1.002 1.013.33.329-1.004c1.135.417 2.216.97 3.218 1.646l-.619.854.863.627.616-.847c.95.746 1.808 1.604 2.555 2.554l-.847.616.627.863.853-.619c.676 1 1.227 2.08 1.643 3.213l-1.006.327.33 1.013 1.002-.326c.333 1.164.523 2.363.565 3.573h-1.053v1.066h1.053a14.853 14.853 0 01-.565 3.57z"></path>
        <path d="M16 4.267C9.52 4.267 4.267 9.52 4.267 16S9.52 27.733 16 27.733 27.733 22.48 27.733 16C27.726 9.523 22.477 4.274 16 4.267zm0 22.4C10.11 26.667 5.333 21.89 5.333 16S10.11 5.333 16 5.333 26.667 10.11 26.667 16C26.66 21.888 21.888 26.66 16 26.667z"></path>
        <path d="M16 6.933A9.067 9.067 0 1025.067 16 9.077 9.077 0 0016 6.933zm6.858 4.97a7.932 7.932 0 011.106 3.382l-3.34-.27a.533.533 0 01-.304-.935l2.538-2.178zm-5.077-3.696L16.492 11.3a.533.533 0 01-.984 0l-1.289-3.093a7.765 7.765 0 013.562 0zm-8.64 3.695l2.539 2.178a.533.533 0 01-.304.937l-3.34.27a7.93 7.93 0 011.106-3.385zm4.492 8.19l-.774 3.264a8.037 8.037 0 01-2.876-2.1h-.001l2.854-1.742a.533.533 0 01.797.579zm5.508 3.264h-.001l-.776-3.263a.533.533 0 01.796-.579l2.855 1.742a8.037 8.037 0 01-2.874 2.1zm.576-4.753a1.6 1.6 0 00-2.39 1.737l.8 3.364a7.797 7.797 0 01-4.255 0h-.002l.8-3.364a1.6 1.6 0 00-2.39-1.736l-2.95 1.801a7.946 7.946 0 01-1.312-4.048l3.444-.279a1.6 1.6 0 00.911-2.809L9.75 11.02a8.027 8.027 0 013.443-2.501l1.33 3.19a1.6 1.6 0 002.953 0l1.33-3.19a8.03 8.03 0 013.442 2.5l-2.62 2.251a1.6 1.6 0 00.912 2.81l3.444.278a7.946 7.946 0 01-1.31 4.048l-2.956-1.802z"></path>
        <path d="M16 13.867a2.133 2.133 0 100 4.266 2.133 2.133 0 000-4.266zm0 3.2a1.067 1.067 0 110-2.133 1.067 1.067 0 010 2.133z"></path>
      </g>
      <defs>
        <clipPath id="clip0_7689_63">
          <path fill="#fff" d="M0 0H32V32H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Automotive;
