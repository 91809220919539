import React from "react";
import { Link } from "gatsby";
import Twitter from "../icons/Twitter";
import FooterLogo from "../icons/FooterLogo";
import Instagram from "../icons/Instagram";
import Facebook from "../icons/Facebook";
import Linkedin from "../icons/Linkedin";

const Footer = () => {
  return (
    <footer
      className="section fp-auto-height auto-height-only-non-mobile"
      data-anchor="footer"
    >
      <div className="page-footer content-container">
        <div className="footer-menu-section flex-row-space-between">
          <div className="d-flex flex-column">
            <div className="logo">
              <Link to="/">
                <div className="erb-logo" aria-label="logo Erbis">
                  <FooterLogo />
                </div>
              </Link>
            </div>
            <div className="slogan text-bold ">
              Your advanced technology partner.
            </div>
          </div>
          <div className="footer-col">
            <nav>
              <ul>
                <li className="has-sub-items">
                  <Link to="/services/">Services</Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/services/software-engineering/">
                        Software Engineering
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/cloud-migration/">
                        Cloud Migration
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/discovery-phase/">
                        Discovery Phase
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/product-development/">
                        Product Development
                      </Link>
                    </li>
                    <li>
                      <Link to="/services/ui-ux-design/">UI/UX Design</Link>
                    </li>
                    {/* <li>
                      <Link to="/services/tech-client-support/">
                        Technical &amp; Сlient Support
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/services/digital-transformation/">
                        Digital Transformation
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="has-sub-items">
                  <Link to="/about-us/">About Us</Link>
                  <ul className="sub-menu">
                    {/* <li>
                      <Link to="/company/">Company</Link>
                    </li> */}
                    <li>
                      <Link to="/about-us/culture/">Culture</Link>
                    </li>
                    <li>
                      <Link to="/about-us/testimonials/">Client Reviews</Link>
                    </li>
                    <li>
                      <Link to="/about-us/how-we-work/">How We Work</Link>
                    </li>
                    <li>
                      <Link to="/contact/">Contacts</Link>
                    </li>
                  </ul>
                </li>
                <li className="has-sub-items">
                  <Link to="/case-studies/">Case Studies</Link>
                  <ul className="sub-menu">
                    <li className="text-bold">
                      <Link to="/blog/">Insights</Link>
                    </li>
                    <li className="text-bold">
                      <Link to="/guides/">Guides</Link>
                    </li>
                    <li className="text-bold">
                      <Link to="/careers/">Careers</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <ul className="social-refs">
          <li>
            <a
              href="https://www.linkedin.com/company/erbis-software-development/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="linkedin"
            >
              <span className="erb-linked-in-with-circle">
                <Linkedin />
              </span>
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/erbiscloud"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="facebook"
            >
              <span className="erb-facebook-with-circle">
                <Facebook />
              </span>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/erbis_official/"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="instagram"
            >
              <span className="erb-instagram-with-circle">
                <Instagram />
              </span>
            </a>
          </li>
          <li>
            <a
              href="https://twitter.com/ErbisCompany"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="twitter"
            >
              <span className="erb-twitter-with-circle">
                <Twitter />
              </span>
            </a>
          </li>
        </ul>
        <div className="copyright flex-row-space-between position-relative mt-4 pt-4">
          <span id="copyright">
            © Copyright {new Date().getFullYear()} Erbis Inc. All rights
            reserved.
          </span>
          <Link to="/policy/privacy/" className="privacy-policy">
            Privacy &amp; Cookies
          </Link>
          {/*A time inset to show the current build*/}
          {/* <div className="footerBuildDisplay"></div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
