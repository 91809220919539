import React from "react";

function ProductDevelopment() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path d="M30.153 3.333H1.847A1.847 1.847 0 000 5.14v18.387a1.84 1.84 0 001.847 1.806h11.586c-.1 1.067-.56 3.454-2.666 3.454h-.46a1.807 1.807 0 00-1.807 1.8v.6a.787.787 0 00.78.813h13.387a.793.793 0 00.793-.787v-.6a1.807 1.807 0 00-1.807-1.8h-.46c-2.12 0-2.58-2.386-2.666-3.453h11.626A1.839 1.839 0 0032 23.527V5.14a1.847 1.847 0 00-1.847-1.807zm-28.306.974h28.306A.847.847 0 0131 5.14v15.113H1V5.14a.847.847 0 01.847-.833zM21.62 29.813a.806.806 0 01.807.8V31H9.487v-.387a.807.807 0 01.806-.8H21.62zm-2.813-1h-5.7a5.833 5.833 0 001.333-3.453h3.033a5.834 5.834 0 001.334 3.453zM30.14 24.36H1.847A.84.84 0 011 23.527v-2.274h30v2.274a.84.84 0 01-.847.833h-.013z"></path>
      <path
        d="M17.06 22.26h-2.12a.5.5 0 000 1h2.12a.5.5 0 000-1zM10.927 15.867l4.813 2.266a.474.474 0 00.38 0l4.813-2.266a.486.486 0 00.294-.447V9.553a.547.547 0 00-.06-.226.46.46 0 00-.147-.154h-.04l-4.813-2.266a.527.527 0 00-.427 0l-4.813 2.266h-.034a.46.46 0 00-.146.154.547.547 0 00-.06.226v5.867a.493.493 0 00.24.447zm.713-5.527l2.667 1.26 1.133.533v4.76L11.627 15.1l.013-4.76zm4.813 6.553v-4.76l1.134-.533 2.666-1.26v4.76l-3.8 1.793zm-.5-9.053l3.64 1.713-1.246.587-2.394 1.12-2.386-1.12-1.247-.587 3.633-1.713z"
      ></path>
    </svg>
  );
}

export default ProductDevelopment;
