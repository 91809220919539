import React from "react";

function Instagram() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      fill="none"
      viewBox="0 0 26 26"
    >
      <g fill="#fff" clipPath="url(#clip0_280_771)">
        <path d="M15.488 13a2.488 2.488 0 11-4.976 0 2.488 2.488 0 014.976 0z"></path>
        <path d="M18.82 8.597a2.313 2.313 0 00-.56-.858 2.311 2.311 0 00-.857-.559c-.263-.102-.659-.223-1.386-.256-.788-.036-1.024-.044-3.017-.044-1.993 0-2.23.007-3.016.043-.728.034-1.124.155-1.386.257a2.31 2.31 0 00-.859.559c-.248.24-.439.534-.558.858-.103.263-.224.658-.257 1.386-.036.787-.044 1.024-.044 3.017 0 1.993.008 2.229.044 3.016.033.728.154 1.123.257 1.386.12.325.31.618.558.859.24.248.534.438.858.558.263.102.659.224 1.387.257.787.036 1.023.044 3.016.044s2.23-.008 3.016-.044c.728-.033 1.124-.155 1.387-.257a2.472 2.472 0 001.416-1.417c.102-.263.224-.658.257-1.386.036-.787.044-1.023.044-3.016s-.008-2.23-.044-3.017c-.033-.728-.154-1.123-.257-1.386zM13 16.833a3.833 3.833 0 110-7.667 3.833 3.833 0 010 7.667zm3.985-6.922a.896.896 0 110-1.792.896.896 0 010 1.792z"></path>
        <path d="M13 0C5.821 0 0 5.821 0 13s5.821 13 13 13 13-5.821 13-13S20.179 0 13 0zm7.42 16.078c-.036.794-.163 1.337-.347 1.812a3.817 3.817 0 01-2.183 2.183c-.475.184-1.018.31-1.812.347-.796.036-1.05.045-3.078.045-2.027 0-2.281-.009-3.078-.045-.794-.037-1.337-.163-1.811-.347a3.659 3.659 0 01-1.323-.861 3.658 3.658 0 01-.86-1.322c-.185-.475-.312-1.018-.348-1.812-.036-.796-.045-1.05-.045-3.078 0-2.027.009-2.282.045-3.078.036-.794.162-1.337.347-1.812.187-.498.481-.949.861-1.322.373-.38.824-.673 1.322-.86.475-.185 1.018-.312 1.812-.348.796-.036 1.05-.045 3.078-.045 2.027 0 2.282.009 3.078.045.794.036 1.337.163 1.812.347.498.187.95.481 1.322.861.38.373.674.824.86 1.322.186.475.312 1.018.348 1.812.036.796.045 1.05.045 3.078 0 2.027-.009 2.282-.045 3.078z"></path>
      </g>
      <defs>
        <clipPath id="clip0_280_771">
          <path fill="#fff" d="M0 0H26V26H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Instagram;
