import React from "react";

function CloudMigration() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <g clipPath="url(#clip0_7689_172)">
        <path d="M5.1 19.86h3.187a.493.493 0 00.5-.5.507.507 0 00-.5-.5H5.1A4 4 0 014 10.993a.506.506 0 00.347-.586 5.547 5.547 0 01-.114-1.074 5.387 5.387 0 017.454-4.986.493.493 0 00.606-.18 6.793 6.793 0 0112.314 2.566.494.494 0 00.486.414 5.827 5.827 0 010 11.653h-1.38a.5.5 0 000 1h1.374a6.827 6.827 0 00.42-13.647A7.787 7.787 0 0011.7 3.28a6.373 6.373 0 00-8.407 6.893A5.014 5.014 0 005.1 19.86z"></path>
        <path d="M16.547 22.087V10a.5.5 0 00-1 0v12.087c-1.747.093-3.334.666-3.334 1.746v5.74c0 1.174 1.94 1.787 3.86 1.787s3.854-.613 3.854-1.787v-5.74c-.027-1.073-1.634-1.653-3.38-1.746zm2.353 4.62c0 .2-.98.786-2.853.786-1.874 0-2.86-.586-2.86-.786v-1.634a6.819 6.819 0 002.86.554 6.766 6.766 0 002.853-.554v1.634zm-2.853-3.66c1.873 0 2.853.586 2.853.786 0 .2-.98.794-2.853.794-1.874 0-2.86-.587-2.86-.794 0-.206.986-.786 2.86-.786zm0 7.333c-1.874 0-2.86-.587-2.86-.787V27.94a6.819 6.819 0 002.86.553 6.767 6.767 0 002.853-.553v1.633c0 .2-.98.787-2.853.787v.02zM23.293 22.967l-2.76-2.927v-4.853a.5.5 0 00-1 0v5.053a.52.52 0 00.14.347L22.86 24v5.607c0 1.173 1.94 1.786 3.86 1.786s3.853-.613 3.853-1.786v-5.774c0-2.013-5.653-2.3-7.28-.866zm6.28 3.74c0 .2-.98.786-2.853.786-1.873 0-2.86-.586-2.86-.786v-1.634c.902.39 1.878.579 2.86.554a6.766 6.766 0 002.853-.554v1.634zm-2.853-3.66c1.873 0 2.853.586 2.853.786 0 .2-.98.794-2.853.794-1.873 0-2.86-.587-2.86-.794 0-.206.98-.786 2.86-.786zm0 7.333c-1.88 0-2.86-.587-2.86-.787V27.94c.902.39 1.878.579 2.86.553a6.767 6.767 0 002.853-.553v1.633c0 .2-.98.787-2.853.787v.02zM12.487 20.587a.487.487 0 00.14-.347v-5.053a.5.5 0 00-1 0v4.853L8.833 23C7.26 21.533 1.5 21.807 1.5 23.833v5.74c.02 1.174 1.96 1.76 3.873 1.76 1.914 0 3.86-.613 3.86-1.786v-5.534l3.254-3.426zM2.52 25.073c.9.39 1.873.578 2.853.554a6.82 6.82 0 002.86-.554v1.634c0 .2-.986.786-2.86.786-1.873 0-2.853-.586-2.853-.786v-1.634zm2.853-2c1.874 0 2.86.587 2.86.787 0 .2-.986.793-2.86.793-1.873 0-2.853-.586-2.853-.793 0-.207.98-.813 2.853-.813v.026zm0 7.334c-1.873 0-2.853-.587-2.853-.787v-1.68c.9.389 1.873.578 2.853.553a6.82 6.82 0 002.86-.553v1.633c0 .2-.986.787-2.86.787v.047z"></path>
      </g>
      <defs>
        <clipPath id="clip0_7689_172">
          <path fill="#fff" d="M0 0H32V32H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default CloudMigration;
