import React, { createContext } from "react";
import { Modal } from "../components/Shared";

const defaultState = {
  isModalOpen: false,
  modalContent: null,
};

export const GetInTouchModalContext = createContext(defaultState);

export class GetInTouchProvider extends React.Component {
  state = {
    isModalOpen: false,
    modalContent: null,
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  openModal = (content) => {
    this.setState({ isModalOpen: true, modalContent: content });
  };

  componentDidUpdate() {
    if (this.state.isModalOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }

  render() {
    const { children } = this.props;
    const { isModalOpen, modalContent } = this.state;
    return (
      <GetInTouchModalContext.Provider
        value={{
          closeModal: this.closeModal,
          openModal: this.openModal,
          isModalOpen,
          modalContent,
        }}
      >
        {children}
        {<Modal visible={isModalOpen} content={modalContent} />}
      </GetInTouchModalContext.Provider>
    );
  }
}
export default GetInTouchModalContext;
