import React, { useState } from "react";
import { Link } from "gatsby";
import SoftwareEngineering from "../icons/SoftwareEngineering";
import CloudMigration from "../icons/CloudMigration";
import DiscoveryPhase from "../icons/DiscoveryPhase";
import ProductDevelopment from "../icons/ProductDevelopment";
import UIDesign from "../icons/UIDesign";
import DTransformation from "../icons/DTransformation";
import SupplyChain from "../icons/SupplyChain";
import Healthcare from "../icons/Healthcare";
import Fintech from "../icons/Fintech";
import RealEstate from "../icons/RealEstate";
import Retail from "../icons/Retail";
import Automotive from "../icons/Automotive";
import About from "../../assets/photos/about-us.webp";
import Insights from "../../assets/photos/insights-image.webp";

// const getDutchPath = () => {
//   if (
//     typeof window !== "undefined" &&
//     ![
//       "/real-estate-software-development-services/",
//       "/real-estate-software-development/",
//     ].includes(window.location.pathname)
//   ) {
//     return `/de${window.location.pathname}`;
//   }

//   return "/de/";
// };

// const hasDutchVersionFunc = () => {
//   if (typeof window !== "undefined") {
//     return (
//       window.location.pathname !== "/blog/" &&
//       !/blog(\/\d+)?/.test(window.location.pathname) &&
//       window.location.pathname !== "/careers/"
//     );
//   }

//   return true;
// };

const menuLinks = [
  "Culture",
  "Client Reviews",
  "How We Work",
  "Articles",
  "Guides",
  "News",
];
const noContent = ["Case Studies", "Careers"];
const menuLeft = ["Industries", "Services", "About Us", "Insights"];
const descriptionLinks = ["About Us", "Insights"];
// const descriptionLinks = ["About Us"];

const SubMenuItem = ({ item }) => (
  <div
    className={`submenu__item ${
      menuLinks.includes(item.label)
        ? "submenu__item-about"
        : "submenu__item-icon"
    }`}
  >
    <Link
      to={item.link}
      className="submenu__link"
      activeClassName="submenu__link-active"
    >
      {!item.remark && (
        <>
          <div
            className={
              item.label !== "UX/UI Design" ? "submenu__icon" : "icon-special"
            }
          >
            {item.icon}
          </div>
          <div
            className={
              item.label !== "UX/UI Design"
                ? " submenu__icon submenu__icon-active submenu__icon-hover"
                : " submenu__icon-active icon-hover-special"
            }
          >
            {item.icon}
          </div>
        </>
      )}

      {item.label}
      {item.remark && (
        <span className="submenu__category-description">{item.remark}</span>
      )}
    </Link>
  </div>
);

const SubMenu = ({
  items,
  title,
  description,
  caseStudyLink,
  img,
  btnName,
}) => (
  <div className="submenu">
    <div className="submenu__container content-container">
      {menuLeft.includes(title) && (
        <div className="submenu__left">
          <img src={img} alt={title} className="submenu__photo" />
          <p className="submenu__description">{description}</p>
          <a href={caseStudyLink} className="submenu__url">
            {btnName}
          </a>
        </div>
      )}

      <div className="submenu__right">
        <p className="submenu__name">{title}</p>
        <div
          className={`submenu__list ${descriptionLinks ? " tablet-gap" : ""}`}
        >
          {items.map((menuItem) => (
            <SubMenuItem key={menuItem.label} item={menuItem} />
          ))}
        </div>
      </div>
    </div>
  </div>
);

const MenuItem = ({ item, onMenuItemClick }) => {
  const handleItemClick = () => {
    onMenuItemClick(item);
  };

  return (
    <div
      className="main-menu__item"
      id={`main-menu__submenu-${item.label.toLowerCase()}`}
    >
      <Link
        to={item.link}
        activeClassName="main-menu__item-active"
        partiallyActive={true}
      >
        {item.label}
      </Link>

      {!noContent.includes(item.label) && (
        <i
          className="menu-arrow"
          onClick={handleItemClick}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === "Space") {
              handleItemClick();
            }
          }}
          role="button"
          tabIndex={0}
        >
          {""}
        </i>
      )}

      {item.submenu && (
        <SubMenu
          items={item.submenu}
          title={item.label}
          description={item.description}
          caseStudyLink={item.caseStudyLink}
          img={item.img}
          btnName={item.btnName}
        />
      )}
    </div>
  );
};
const Header = () => {
  const [selectedMenu, setSelectedMenu] = useState(null);

  const handleMenuItemClick = (menuItem) => {
    setSelectedMenu(menuItem);
  };

  const handleBackClick = () => {
    setSelectedMenu(null);
  };

  typeof window !== "undefined" &&
    document.body.addEventListener("click", (event) => {
      if (event.target.matches(".arrow-down, .main-menu__about-us")) {
        const parentMenu = event.target.closest(".main-menu__item");
        if (parentMenu) {
          parentMenu.classList.toggle("active-sub-menu");
        }
      } else {
        const dropdowns = document.querySelectorAll(
          ".main-menu__item.active-sub-menu"
        );
        dropdowns.forEach((openDropdown) => {
          openDropdown.classList.remove("active-sub-menu");
        });
      }
    });
  const menuItems = [
    {
      label: "Services",
      link: "/services/",
      submenu: [
        {
          label: "Software Engineering",
          link: "/services/software-engineering/",
          icon: <SoftwareEngineering />,
        },
        {
          label: "Cloud Migration",
          link: "/services/cloud-migration/",
          icon: <CloudMigration />,
        },
        {
          label: "Discovery Phase",
          link: "/services/discovery-phase/",
          icon: <DiscoveryPhase />,
        },
        {
          label: "Product Development",
          link: "/services/product-development/",
          icon: <ProductDevelopment />,
        },
        {
          label: "UX/UI Design",
          link: "/services/ui-ux-design/",
          icon: <UIDesign />,
        },
        {
          label: "Digital Transformation",
          link: "/services/digital-transformation/",
          icon: <DTransformation />,
        },
      ],
      description:
        "Building an advanced print MIS and enterprise resource planning (ERP) solution",
      btnName: "View Case Study",
      caseStudyLink: "/case-studies/",
      img: "/../../assets/photos/menu-image1-2a52aabab1ee7bf529b4.webp",
    },
    {
      label: "Industries",
      link: "/industries/",
      submenu: [
        {
          label: "Supply Chain",
          link: "/industries/scm-supply-chain-management-software-solutions/",
          icon: <SupplyChain />,
        },
        {
          label: "Healthcare",
          link: "/industries/healthcare-software-development/",
          icon: <Healthcare />,
        },
        {
          label: "Fintech",
          link: "/industries/fintech-solutions/",
          icon: <Fintech />,
        },
        {
          label: "Real Estate",
          link: "/industries/construction-real-estate-software/",
          icon: <RealEstate />,
        },
        {
          label: "Retail",
          link: "/industries/retail-software-development/",
          icon: <Retail />,
        },
        {
          label: "Automotive",
          link: "/industries/automotive-software-development/",
          icon: <Automotive />,
        },
      ],
      description: "Re-architecture cloud structure for SaaS",
      btnName: "View Case Study",
      caseStudyLink: "/case-studies/",
      img: "/../../assets/photos/menu-image2-d05b51c9f83340c3822f.webp",
    },
    {
      label: "About Us",
      link: "/about-us/",
      submenu: [
        {
          label: "Culture",
          link: "/about-us/culture/",
          icon: "",
          remark:
            "We build a family of passionate engineers around modern technologies.",
        },
        {
          label: "Client Reviews",
          link: "/about-us/testimonials/",
          icon: "",
          remark:
            "We treat each project as uniquely significant and highly value our clients' trust.",
        },
        {
          label: "How We Work",
          link: "/about-us/how-we-work/",
          icon: "",
          remark:
            "We create multi-functional teams that deliver true value to customers.",
        },
      ],
      description: "Check Online Reviews on the Internet",
      btnName: "See Reviews",
      caseStudyLink: "https://www.topdevelopers.co/profile/erbis",
      img: About,
    },
    {
      label: "Case Studies",
      link: "/case-studies/",
    },
    {
      label: "Insights",
      link: "/blog/",
      submenu: [
        {
          label: "Articles",
          link: "/blog/",
          icon: "",
          remark:
            "Discover blog posts covering technology topics and trend overviews.",
        },
        {
          label: "Guides",
          link: "/guides/",
          icon: "",
          remark:
            "Explore extensive publications offering detailed insights into topics.",
        },
        {
          label: "News",
          link: "/blog/category/latest-news/",
          icon: "",
          remark:
            "Stay updated with the latest highlights and news from our dynamic team.",
        },
      ],
      description: "How to Migrate to New Tech Without Business Harm",
      btnName: "Download a PDF",
      caseStudyLink: "/download/tech-migration/",
      img: Insights,
    },
    {
      label: "Careers",
      link: "/careers/",
    },
  ];
  return (
    <header className="erb-header flex-center position-fixed" id="top-nav-menu">
      <label htmlFor="toggle-menu" className="show-menu cursor-pointer">
        {""}
        <span className="erb-menu" />
      </label>
      <input type="checkbox" id="toggle-menu" role="button" />
      <label htmlFor="toggle-menu" className="close-menu cursor-pointer">
        {""}
        <span className="erb-close-menu" />
      </label>
      <div className="nav-container content-container">
        <Link to="/" className="erb-logo" aria-label="logo Erbis" />
        <nav className="d-flex flex-grow-1">
          <div className="main-menu d-flex flex-grow-1 tablet-mobile-menu">
            <React.Fragment>
              <ul className="mobile-menu">
                {menuItems.map((menuItem) => (
                  <MenuItem
                    key={menuItem.label}
                    item={menuItem}
                    onMenuItemClick={handleMenuItemClick}
                  />
                ))}
              </ul>
              {selectedMenu && (
                <div
                  className={`box-menu ${
                    selectedMenu !== null
                      ? "translate-menu"
                      : "no-translate-menu"
                  }`}
                >
                  <div className="btn-back-menu">
                    <i
                      className="menu-arrow"
                      onClick={handleBackClick}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" || e.key === "Space") {
                          handleBackClick();
                        }
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      {""}
                    </i>

                    {selectedMenu.label}
                  </div>
                  {selectedMenu.submenu && (
                    <SubMenu items={selectedMenu.submenu} />
                  )}
                </div>
              )}
            </React.Fragment>
          </div>
        </nav>
        <div className="secondary-nav flex-column-center">
          {/* Hide DE version from the site */}
          {/* {hasDutchVersion && (
            <div className="lang-panel">
              <ul>
                <li className="active">
                  <Link to="/">en</Link>
                </li>
                <li>
                  <Link to={dutchPath}>de</Link>
                </li>
              </ul>
            </div>
          )} */}

          <Link
            to="/contact/"
            className="erb-button  text-nowrap"
            id="header-btn-menu"
          >
            Contact Us
          </Link>
        </div>
      </div>
    </header>
  );
};

export default Header;
