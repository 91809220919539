import React, { useContext } from "react";
import GetInTouchModalContext from "../../context/GetInTouchModal";

const Modal = ({ visible }) => {
  const { closeModal, modalContent } = useContext(GetInTouchModalContext);
  return (
    visible && (
      <div className={`modal-form ${visible ? "" : "hide"}`} id="modal">
        <div className="modal-wrapper">
          <button className="close-button" onClick={closeModal}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="#ffffff"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="Frame 44">
                <rect
                  id="Rectangle 10"
                  x="14.2028"
                  y="0.14209"
                  width="1.5"
                  height="20"
                  rx="0.75"
                  transform="rotate(45 14.2028 0.14209)"
                  fill="#ffffff"
                />
                <rect
                  id="Rectangle 11"
                  x="15.2028"
                  y="14.1421"
                  width="1.5"
                  height="20"
                  rx="0.75"
                  transform="rotate(135 15.2028 14.1421)"
                  fill="#ffffff"
                />
              </g>
            </svg>
            {""}
          </button>

          {modalContent && <div>{modalContent}</div>}
        </div>
      </div>
    )
  );
};

export default Modal;
