import React from "react";

function DTransformation() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path d="M23.747 15.5h-7.3a2.667 2.667 0 100 1h7.3a1.606 1.606 0 100-1zm-9.94 2.193a1.694 1.694 0 110-3.388 1.694 1.694 0 010 3.388zM25.873 16a.607.607 0 11-1.213 0 .607.607 0 011.213 0z"></path>
      <path d="M29.153 19.107a1.614 1.614 0 00-1.52 1.106h-8.706a.54.54 0 00-.367.153 6.247 6.247 0 01-4.56 1.96A6.333 6.333 0 1114 9.66a6.247 6.247 0 014.567 1.96.54.54 0 00.366.153H27.6a1.607 1.607 0 10.594-1.79 1.6 1.6 0 00-.58.79h-8.48A7.248 7.248 0 0014 8.667a7.333 7.333 0 100 14.666 7.247 7.247 0 005.14-2.113h8.493a1.605 1.605 0 002.83.44 1.607 1.607 0 00-1.31-2.547v-.006zm0-8.414a.606.606 0 11-.606.607.606.606 0 01.606-.634v.027zm0 10.667a.613.613 0 11.607-.607.607.607 0 01-.607.58v.027z"></path>
      <path d="M23.193 25.573a1.614 1.614 0 00-1.52 1.094H16.76a.5.5 0 00-.487.366l-.52 1.887H12.36l-.52-1.887a.48.48 0 00-.367-.353 10.787 10.787 0 01-3.266-1.347.507.507 0 00-.514 0l-1.76.96-2.4-2.4 1.04-1.826a.5.5 0 000-.5 10.908 10.908 0 01-1.24-2.987.493.493 0 00-.353-.367l-1.967-.54V14.28l2-.547a.492.492 0 00.32-.36c.258-1.062.674-2.08 1.234-3.02a.507.507 0 000-.506L3.533 8l2.4-2.413L7.747 6.62a.507.507 0 00.513 0 10.973 10.973 0 013.227-1.333.48.48 0 00.366-.354L12.36 3.1h3.393l.507 1.833a.5.5 0 00.48.367h4.927a1.607 1.607 0 100-1h-4.54l-.507-1.833a.507.507 0 00-.487-.367H12a.5.5 0 00-.48.367l-.533 1.92A11.813 11.813 0 008 5.607L6.1 4.533a.493.493 0 00-.6.08l-2.94 2.94a.493.493 0 00-.08.6l1.1 1.94a12.081 12.081 0 00-1.127 2.754L.4 13.413a.507.507 0 00-.367.48v4.16a.5.5 0 00.367.48l2.053.567c.257.95.629 1.866 1.107 2.727l-1.08 1.906a.493.493 0 00.08.6l2.94 2.94a.493.493 0 00.6.08l1.833-1.04c.944.56 1.96.989 3.02 1.274l.547 2a.5.5 0 00.48.366h4.153a.506.506 0 00.487-.366l.52-1.887h4.527a1.606 1.606 0 101.526-2.1v-.027zm0-21.393a.607.607 0 110 1.214.607.607 0 010-1.214zm0 23.607a.606.606 0 110-1.212.606.606 0 010 1.212z"></path>
    </svg>
  );
}

export default DTransformation;
